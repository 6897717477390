import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Table, Form, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

type PermissionKey =
  | 'access'
  | 'view'
  | 'create'
  | 'edit'
  | 'delete'
  | 'details'
  | 'exportExcel'
  | 'exportPdf';

interface Screen {
  id: number;
  name: string;
}

interface ScreenPermission {
  screenId: number;
  permissions: { [K in PermissionKey]: boolean };
}

const defaultPermissions: { [K in PermissionKey]: boolean } = {
  access: false,
  view: false,
  create: false,
  edit: false,
  delete: false,
  details: false,
  exportExcel: false,
  exportPdf: false,
};

const permissionLabels: { [K in PermissionKey]: string } = {
  access: 'Acessar Página',
  view: 'Visualizar Registros',
  create: 'Criar',
  edit: 'Editar',
  delete: 'Excluir',
  details: 'Detalhes',
  exportExcel: 'Exportar Excel',
  exportPdf: 'Exportar PDF',
};

const PermissionPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const [screens, setScreens] = useState<Screen[]>([]);
  const [screenPermissions, setScreenPermissions] = useState<
    ScreenPermission[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetchScreens();
  }, []);

  const fetchScreens = async () => {
    try {
      const token = sessionStorage.getItem('authToken');
      const response = await axios.get<{ screens: Screen[] }>(
        `${import.meta.env.VITE_APP_API_URL}/screens`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setScreens(response.data.screens);
      fetchPermissionsForAllScreens(response.data.screens);
    } catch (error) {
      console.error('Erro ao buscar telas:', error);
      toast.error('Erro ao carregar as telas.');
    }
  };

  const parsePermissions = (
    permissions: string | string[] | null | undefined
  ): { [K in PermissionKey]: boolean } => {
    console.log('Permissões recebidas:', permissions);

    if (!permissions) {
      console.log('Permissões são null ou undefined');
      return { ...defaultPermissions };
    }

    let permissionsArray: string[];
    if (Array.isArray(permissions)) {
      permissionsArray = permissions;
    } else if (typeof permissions === 'string') {
      permissionsArray = permissions.split(',').map((p) => p.trim());
    } else {
      console.log('Formato de permissões desconhecido');
      return { ...defaultPermissions };
    }

    return Object.keys(defaultPermissions).reduce(
      (acc, perm) => {
        acc[perm as PermissionKey] = permissionsArray.includes(perm);
        return acc;
      },
      { ...defaultPermissions }
    );
  };

  const fetchPermissionsForAllScreens = async (screens: Screen[]) => {
    try {
      const token = sessionStorage.getItem('authToken');
      const permissionsPromises = screens.map((screen) =>
        axios.get<{
          user_id: number;
          screen_id: number;
          permissions: string | string[] | null;
        }>(
          `${import.meta.env.VITE_APP_API_URL}/screens/${
            screen.id
          }/permissions/${userId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
      );
      const permissionsResponses = await Promise.all(permissionsPromises);
      const screenPermissions = permissionsResponses.map((response) => {
        console.log(
          'Resposta do servidor para a tela',
          response.data.screen_id,
          ':',
          response.data
        );
        return {
          screenId: response.data.screen_id,
          permissions: parsePermissions(response.data.permissions),
        };
      });
      setScreenPermissions(screenPermissions);
    } catch (error) {
      console.error('Erro ao buscar permissões:', error);
      toast.error('Erro ao carregar as permissões.');
    } finally {
      setLoading(false);
    }
  };

  const handlePermissionChange = (
    screenId: number,
    permission: PermissionKey
  ) => {
    setScreenPermissions((prevPermissions) =>
      prevPermissions.map((screenPerm) =>
        screenPerm.screenId === screenId
          ? {
              ...screenPerm,
              permissions: {
                ...screenPerm.permissions,
                [permission]: !screenPerm.permissions[permission],
              },
            }
          : screenPerm
      )
    );
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const token = sessionStorage.getItem('authToken');
      const savePromises = screenPermissions.map((screenPerm) =>
        axios.put(
          `${import.meta.env.VITE_APP_API_URL}/screens/${
            screenPerm.screenId
          }/permissions`,
          {
            user_id: userId,
            permissions: Object.keys(screenPerm.permissions).filter(
              (key) => screenPerm.permissions[key as PermissionKey]
            ),
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
      );
      await Promise.all(savePromises);
      toast.success('Permissões salvas com sucesso!');
      navigate('/admin');
    } catch (error) {
      console.error('Erro ao salvar permissões:', error);
      if (axios.isAxiosError(error) && error.response) {
        const errorMessage =
          error.response.data.message ||
          'Erro desconhecido ao salvar as permissões.';
        toast.error(`Erro ao salvar as permissões: ${errorMessage}`);
      } else {
        toast.error(
          'Erro ao salvar as permissões. Por favor, tente novamente.'
        );
      }
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <div className="container mt-4">
      <h1>Permissões do Usuário</h1>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Tela</th>
            {Object.values(permissionLabels).map((label) => (
              <th key={label}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {screens.map((screen) => {
            const screenPerm = screenPermissions.find(
              (sp) => sp.screenId === screen.id
            );
            return (
              <tr key={screen.id}>
                <td>{screen.name}</td>
                {Object.keys(permissionLabels).map((perm) => (
                  <td key={perm}>
                    <Form.Check
                      type="checkbox"
                      checked={
                        screenPerm?.permissions[perm as PermissionKey] || false
                      }
                      onChange={() =>
                        handlePermissionChange(screen.id, perm as PermissionKey)
                      }
                    />
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Button variant="primary" onClick={handleSave} disabled={saving}>
        {saving ? 'Salvando...' : 'Salvar Permissões'}
      </Button>
    </div>
  );
};

export default PermissionPage;
